import {isSuccessfulCall, ServiceCall} from "./ServiceCall";
import {ServiceRoutes, ServiceVersions} from "./ApiEnums";
import {NavigateToRegister} from "../functions/NavigationFunctions";
import {GenericErrorCaller, GenericErrorCallerForCatch} from "../components/sweetAlert/GenericAlerts";
import {ApiFacebookLogin} from "./LoginServices";
import Cookies from "universal-cookie";


/*
 * CLASSIC REGISTRATION
 */

type IParametersClassicRegister = {
    device_token:string; //For android apps: Firebase ID
    itunes_unique_vendor_identifier?:string; //For iphone apps:
    android_id?:string; //For android apps:
    email:string;
    gender:number;
    facebook_user_id?:string; //Facebook ile kayıtta gönderilmeli.
    facebook_access_token?:string; //Facebook ile kayıtta gönderilmeli.
    password?:string;
    name?:string;
    source?:string;
    referrer?:string;
    utm_source?:string; //referrer içinde varsa bu parametreye gerek yok.
    utm_medium?:string; //referrer içinde varsa bu parametreye gerek yok.
    utm_campaign?:string; //referrer içinde varsa bu parametreye gerek yok.
    referrer_user_id?:number; //referrer içinde varsa bu parametreye gerek yok.
    appsflyer_id?:string; //appsflyer_id
    ios_idfa?:string; //ios_idfa
    android_advertising_id?:string; //android_advertising_id
    is_limit_ad_tracking_enabled?:boolean; //is_limit_ad_tracking_enabled https://www.npmjs.com/package/react-native-advertising-id
    ios_device_check_token?:string; //ios device check token: https://developer.apple.com/documentation/devicecheck
    carrier?:string;
    is_emulator?:boolean;
    locales?:string;
    temperature_unit?:string;
    uses_metric_system?:boolean;
    currencies?:string;
    country?:string;
    screen_width: number;
    screen_height: number;
    fbc:string;
    fbp:string;
    gclid:string;
    utm_term:string;
    ad_group_id:string;
    landing_page:string;
    loggedin_before:string;
    custom_ab_test_info:string;
    battery_percentage:string;
    email_or_phone_number:string;
    gelir_ortaklari_aff_id:string;
};

export type IResponseClassicRegister = {
    result_code:number;
    data:{
        user_id:number;
        session_id:string;
        access_token:string;
        refresh_token:string;
        session:object;
        profile:object;
        auto_password:boolean;
        proceed_to_home_page:boolean;
        proceed_to_photo_page:boolean;
        jwt:string;
    }
}

export function ApiClassicRegistration(
    email : string,
    gender : 0|1
){

    return new Promise((resolve,reject)=>{

        const parameters : IParametersClassicRegister = {
            device_token : '',
            email: email,
            gender : gender,
            screen_width : screen.width,
            screen_height : screen.height,
            fbc: '',
            fbp: '',
            name: '',
            gclid: '',
            source: '',
            carrier: '',
            country: '',
            ios_idfa: '',
            locales: '',
            utm_term: '',
            password: '',
            referrer: '',
            ad_group_id: '',
            android_id: '',
            utm_medium: '',
            utm_source: '',
            utm_campaign: '',
            currencies: '',
            appsflyer_id: '',
            landing_page: '',
            loggedin_before: '',
            temperature_unit: '',
            custom_ab_test_info: '',
            battery_percentage: '',
            email_or_phone_number: email,
            gelir_ortaklari_aff_id: '',
            ios_device_check_token: '',
            android_advertising_id: '',
            itunes_unique_vendor_identifier: '',
            facebook_user_id: '',
            facebook_access_token: '',
            referrer_user_id: 0,
            is_limit_ad_tracking_enabled: true,
        };

        ServiceCall('POST',ServiceRoutes.Register, parameters, ServiceVersions.v10).then((response:any)=>{
            const result : IResponseClassicRegister = response.data;

            const jwt = result.data.jwt;
            const jwtToken = new Cookies();
            jwtToken.set('jwt', jwt, {path: '/'});

            if(isSuccessfulCall(result)){
                NavigateToRegister(
                    result.data.session_id
                );
                resolve(response.data.error_detail_message);
            }else{
                // GenericErrorCaller(response);
                reject(response.data.error_detail_message);
            }
        }).catch((error:any)=>{
            // GenericErrorCallerForCatch(error);
            reject(error.response.data.error_detail_message);
        });

    });

}




/*
 * FACEBOOK REGISTRATION
 */

export function TryFacebookRegisterOrLogin(response: any){

    if(
        "accessToken" in response &&
        "userID" in response
    ){
        ApiFacebookRegistration(
            response.accessToken,
            response.userID
        );
    }else{
        // todo : Hata
        GenericErrorCaller();
    }

}

export interface IParametersFacebookRegister {
    device_token:string; //For android apps: Firebase ID , For iphone apps: ???
    itunes_unique_vendor_identifier?:string; //For iphone apps:
    facebook_user_id:string; //Facebook ile kayıtta gönderilmeli.
    facebook_access_token:string; //Facebook ile kayıtta gönderilmeli.
    source?:string;
    referrer?:string;
    utm_source?:string; //referrer içinde varsa bu parametreye gerek yok.
    utm_medium?:string; //referrer içinde varsa bu parametreye gerek yok.
    utm_campaign?:string; //referrer içinde varsa bu parametreye gerek yok.
    referrer_user_id?:number; //referrer içinde varsa bu parametreye gerek yok.
    android_id?:string; //For android apps:
    appsflyer_id?:string; //appsflyer_id
    ios_idfa?:string; //ios_idfa
    android_advertising_id?:string; //android_advertising_id
    is_limit_ad_tracking_enabled?:boolean; //is_limit_ad_tracking_enabled https://www.npmjs.com/package/react-native-advertising-id
    ios_device_check_token?:string; //ios device check token: https://developer.apple.com/documentation/devicecheck
    carrier?:string;
    is_emulator?:boolean;
    locales?:string;
    temperature_unit?:string;
    uses_metric_system?:boolean;
    currencies?:string;
    country?:string;
    screen_width: number;
    screen_height: number;
    fbc:string;
    fbp:string;
    gclid:string;
    utm_term:string;
    ad_group_id:string;
    landing_page:string;
    loggedin_before:string;
    custom_ab_test_info:string;
    battery_percentage:string;
    gelir_ortaklari_aff_id:string;
}

export interface IResponseFacebookRegister {
    result_code:number;
    error_code: number;
    error_detail_message: string;
    data:{
        user_id:number;
        session_id:string;
        access_token:string;
        refresh_token:string;
        session:object;
        profile:object;
        auto_password:boolean;
        proceed_to_home_page:boolean;
        proceed_to_photo_page:boolean;
    }
}

export function ApiFacebookRegistration(
    accessToken : string,
    userId: string
){
    return new Promise((resolve, reject)=>{
        const parameters : IParametersFacebookRegister = {
            device_token : '',
            facebook_access_token: accessToken,
            facebook_user_id : userId,
            screen_width : screen.width,
            screen_height : screen.height,
            fbc: '',
            fbp: '',
            gclid: '',
            source: '',
            carrier: '',
            country: '',
            ios_idfa: '',
            locales: '',
            utm_term: '',
            referrer: '',
            ad_group_id: '',
            android_id: '',
            utm_medium: '',
            utm_source: '',
            utm_campaign: '',
            currencies: '',
            appsflyer_id: '',
            landing_page: '',
            loggedin_before: '',
            temperature_unit: '',
            custom_ab_test_info: '',
            battery_percentage: '',
            gelir_ortaklari_aff_id: '',
            ios_device_check_token: '',
            android_advertising_id: '',
            itunes_unique_vendor_identifier: '',
            referrer_user_id: 0,
            is_limit_ad_tracking_enabled: true,
        };
        ServiceCall('POST', ServiceRoutes.RegisterFacebook, parameters, ServiceVersions.v10).then((response:any)=>{
            const result : IResponseFacebookRegister = response.data;
            if(isSuccessfulCall(result)){
                NavigateToRegister(
                    result.data.session_id
                );
                resolve(response.data.error_detail_message);
            }else{
                if(
                    1533 === result.error_code ||
                    1311 === result.error_code
                ){
                    return ApiFacebookLogin(
                        accessToken,
                        userId
                    );
                }else if(
                    1538 === result.error_code
                ){
                    // todo : facebook access token değişmeli
                }else{
                    // GenericErrorCaller(response);
                    reject(response.data.error_detail_message);
                }
            }
        }).catch((error:any)=>{
            if(
                1533 === error.response.data.error_code ||
                1311 === error.response.data.error_code
            ){
                return ApiFacebookLogin(
                    accessToken,
                    userId
                );
            }else if(
                1538 === error.response.data.error_code
            ){
                // todo : facebook access token değişmeli
            }else{
                // GenericErrorCaller(response);
                reject(error.response.data.error_detail_message);
            }
        });
    });
}
