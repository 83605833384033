import {IHeaders, ServiceRoutes, ServiceVersions} from "./ApiEnums";
import {getTimeZone} from "../utils/Timezone";
import {getAppVersion, getHostOrigin, getUniqueCookieId} from "../utils/Generators";
import axios from "axios";
import Cookies from "universal-cookie";

export function getBaseUrlForApi (version:ServiceVersions){
    if (version === ServiceVersions.v10) {
        return '//' + getHostOrigin() + '/api/user/v' + version + '/';
    } else {
        return '//' + getHostOrigin() + '/api/v' + version + '/';
    }
}

export function ServiceCall(
    method: 'GET'|'POST'|'PUT'|'DELETE',
    route: ServiceRoutes | string,
    parameters : any,
    version : ServiceVersions = ServiceVersions.v8
){

    const cookies = new Cookies();

    let tokenCookie = cookies.get('jwt');

    const headers : IHeaders = {
        'accept-language' : 'tr-TR',
        'x-unique-cookie-id' : getUniqueCookieId(),
        'x-react-version' : getAppVersion(),
        'x-timezone' : getTimeZone()
    };

    if (tokenCookie) {
        headers['Authorization'] = 'Bearer ' + tokenCookie;
    }

    const axiosInstance = axios.create({
        baseURL: getBaseUrlForApi(version),
        headers : {
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Headers': '*',
            ...headers
        }
    });

    switch (method) {
        case 'GET':
        case 'DELETE': {
            /* Get the api url from parameters */
            let api_url : string = route;
            if (isEmpty(parameters)) {
                api_url = route;
            } else {
                let parametersString = getStringFromParameters(parameters);
                api_url = route + '?' + parametersString;
            }

            if ('GET' === method) {
                return axiosInstance.get(api_url);
            }

            return axiosInstance.delete(api_url);
        }
        case 'POST':
            return axiosInstance.post(route, parameters);
        case 'PUT':
            return axiosInstance.put(route, parameters);
        default:
            return axiosInstance.post(route, parameters);
    }

}

function getStringFromParameters(parameters:any) {
    let parametersString = "";
    for (let prop in parameters) {
        parametersString += "&" + `${prop} = ${parameters[prop]}`;
    }
    return parametersString.split(' ').join('').substr(1);
}

const hasOwnProperty = Object.prototype.hasOwnProperty;

export function isEmpty(obj:any) {

    // null and undefined are "empty"
    if (null == obj) {
        return true;
    }

    // Assume if it has a length property with a non-zero value
    // that that property is correct.
    if (0 < obj.length) {
        return false;
    }
    if (0 === obj.length) {
        return true;
    }

    // If it isn't an object at this point
    // it is empty, but it can't be anything *but* empty
    // Is it empty?  Depends on your application.
    if ("object" !== typeof obj) {
        return true;
    }

    // Otherwise, does it have any properties of its own?
    // Note that this doesn't handle
    // toString and valueOf enumeration bugs in IE < 9
    for (let key in obj) {
        if (hasOwnProperty.call(obj, key)) {
            return false;
        }
    }

    return true;
}


export function isSuccessfulCall ( result : any ){
    return (
        result &&
        result.result_code &&
        result.result_code === 1
    );
}
