import React, {useEffect} from "react";
import Colors from "../../constants/Colors";
import ButtonComp from "../buttons/buttonComp/buttonComp";
import {TryFacebookRegisterOrLogin} from "../../api/RegistrationServies";
import Script from "next/script";

type IProps = {
    mobileVisible?: boolean;
}

const FacebookLoginButton = (
    {
        mobileVisible = true
    }: IProps
) => {

    const [preventAction, setPreventAction] = React.useState(false);




    const login = () => {
        FB.login((response) => {
            if (response.status === 'connected') {
                setPreventAction(false);
                TryFacebookRegisterOrLogin(response.authResponse);
            }
        }, {scope: 'email, public_profile, user_photos'});
    }

    return (
        <React.Fragment>
            <ButtonComp backgroundColor={Colors.facebookColor} visible={mobileVisible} fullWidth={true} career={true}
                        textColor={true}
                        uppercase={true} leftIcon={'/next/assets/icons/facebook_login.svg'}
                        onClick={() => {
                            if (preventAction) {
                                return null;
                            }
                            setPreventAction(true);
                            login();
                        }}>
                {'Facebook ile Giriş Yap'}
            </ButtonComp>


            {/* eslint-disable-next-line @next/next/inline-script-id */}
            {/*<Script>*/}
            {/*    {`window.fbAsyncInit = function () {*/}
            {/*        window.FB.init({*/}
            {/*            appId: ${process.env.NEXT_PUBLIC_FACEBOOK_ID},*/}
            {/*            autoLogAppEvents: true,*/}
            {/*            version: 'v16.0'*/}
            {/*        });*/}
            {/*    };`}*/}
            {/*</Script>*/}
            {/*<Script async defer crossOrigin="anonymous" src="https://connect.facebook.net/en_US/sdk.js" />*/}
        </React.Fragment>
    );
};

export default FacebookLoginButton;
