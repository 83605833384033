import {isSuccessfulCall, ServiceCall} from "./ServiceCall";
import {ServiceRoutes, ServiceVersions} from "./ApiEnums";
import {GenericErrorCaller, GenericErrorCallerForCatch} from "../components/sweetAlert/GenericAlerts";
import {NavigateToHome, NavigateToRegister} from "../functions/NavigationFunctions";


/*
 * FACEBOOK LOGIN
 */

export interface IParametersFacebookLogin {
    facebook_user_id:string; //For facebook login
    facebook_access_token:string | undefined; //For facebook login
    itunes_unique_vendor_identifier?:string; //For iphone apps:
    device_token:string; //android registration id OR ios device token
    source?:string; //For facebook canvas
    appsflyer_id?:string; //appsflyer_id
    ios_idfa?:string; //ios_idfa
    android_id?:string; //android_id
    android_advertising_id?:string; //android_advertising_id
    is_limit_ad_tracking_enabled?:boolean; //is_limit_ad_tracking_enabled https://www.npmjs.com/package/react-native-advertising-id
    ios_device_check_token?:string; //ios device check token: https://developer.apple.com/documentation/devicecheck
    fbc?:string; //facebook click id
    fbp?:string; //facebook click id
    gclid?:string; //google click id
}
export interface IResponseFacebookLogin {
    result_code:number;
    error_code:number;
    error_detail_message:string;
    data:{
        user_id:number;
        session_id:string;
        access_token:string;
        refresh_token:string;
        self_auth_code:string;
        pusher_channel:string;
        session:object;
        profile:object;
        jwt:string;
    }
}

export function ApiFacebookLogin(
    accessToken : string | undefined,
    userId: string
){

    return new Promise((resolve, reject)=>{
        const parameters : IParametersFacebookLogin = {
            device_token : '',
            facebook_access_token: accessToken,
            facebook_user_id : userId,
            fbc: '',
            fbp: '',
            gclid: '',
            source: '',
            ios_idfa: '',
            android_id: '',
            android_advertising_id: '',
            is_limit_ad_tracking_enabled: true,
            ios_device_check_token: '',
            itunes_unique_vendor_identifier: '',
        };
        ServiceCall('POST', ServiceRoutes.LoginFacebook, parameters, ServiceVersions.v10).then((response:any)=>{

            const result : IResponseFacebookLogin = response.data;

            if(isSuccessfulCall(response.data)){
                NavigateToHome(
                    result.data.session_id,
                    result.data.access_token,
                    result.data.jwt,
                );
                resolve(response.data.error_detail_message);
            }else if(
                1371 === result.error_code
            ){
                NavigateToRegister(result.data.session_id);
                resolve(response.data.error_detail_message);
            }else{
                // GenericErrorCaller(response);
                reject(response.data.error_detail_message);
            }

        }).catch((error:any)=>{
            // GenericErrorCallerForCatch(error);
            reject(error.response.data.error_detail_message);
        });
    });

}


/*
 * CLASSIC LOGIN
 */

export interface IParametersClassicLogin {
    email:string; //Email veya phone number
    password:string;
    itunes_unique_vendor_identifier?:string; //For iphone apps:
    android_id?:string; //For android apps:
    device_token:string; //android registration id OR ios device token
    source?:string; //For facebook canvas
    appsflyer_id?:string; //appsflyer_id
    ios_idfa?:string; //ios_idfa
    android_advertising_id?:string; //android_advertising_id
    is_limit_ad_tracking_enabled?:boolean; //is_limit_ad_tracking_enabled https://www.npmjs.com/package/react-native-advertising-id
    ios_device_check_token?:string; //ios device check token: https://developer.apple.com/documentation/devicecheck
    fbc?:string; //facebook click id
    fbp?:string; //facebook click id
    gclid?:string; //google click id
    remember_two_weeks: 0 | 1;
}
export interface IResponseClassicLogin {
    result_code:number;
    error_code:number;
    error_detail_message:string;
    data:{
        user_id:number;
        session_id:string;
        access_token:string;
        refresh_token:string;
        self_auth_code:string;
        pusher_channel:string;
        session:object;
        profile:object;
        jwt:string;
    }
}

export function ApiClassicLogin(
    email : string,
    password: string,
    remember_two_weeks: 0 | 1
){

    return new Promise((resolve, reject)=>{
        const parameters : IParametersClassicLogin = {
            device_token : '',
            email: email,
            password: password,
            fbc: '',
            fbp: '',
            gclid: '',
            source: '',
            ios_idfa: '',
            android_id: '',
            android_advertising_id: '',
            is_limit_ad_tracking_enabled: true,
            ios_device_check_token: '',
            itunes_unique_vendor_identifier: '',
            remember_two_weeks: remember_two_weeks,
        };
        ServiceCall('POST', ServiceRoutes.Login, parameters, ServiceVersions.v10).then((response:any)=>{

            const result : IResponseClassicLogin = response.data;

            if(isSuccessfulCall(result)){
                NavigateToHome(
                    result.data.session_id,
                    result.data.access_token,
                    result.data.jwt,
                );
                resolve(response.data.error_detail_message);
            }else if(
                1371 === result.error_code
            ){
                NavigateToRegister(result.data.session_id);
                resolve(response.data.error_detail_message);
            }else{
                // GenericErrorCaller(response);
                reject(response.data.error_detail_message);
            }

        }).catch((error:any)=>{
            // console.log(error);
            // GenericErrorCallerForCatch(error);
            if (error.response.data.error_code === 1371) {
                NavigateToRegister(error.response.data.data.jwt);
            }
            reject(error.response.data.error_detail_message);
        });
    });

}
