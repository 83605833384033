import React, {ChangeEvent} from "react";
import styled from "styled-components";
import {deviceMax} from "../../../../styles/device";
import Colors from "../../../constants/Colors";
import { useRouter } from 'next/router';

type IProps = {
    title?: string;
    placeholder: string;
    changeWriting: (event: ChangeEvent<HTMLInputElement>) => void;
    value?:string;
    onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
    handleAlertClose?: () => void;
};

const InputComp = ({
                       title,
                       placeholder,
                       changeWriting = () => {
                       },
                       value = '',
                       handleAlertClose = () => {
                       },
                       onKeyDown = () => {
                       },
                   }: IProps) => {

    const router = useRouter();

    const isAMP = () => {
        return router.query.amp === '1';
    }

    return (
        <StyledInputComp onClick={handleAlertClose}>
            <InputTitleComp>
                {title}
            </InputTitleComp>
            <InputCheckboxComp>
                <input type="text"
                       placeholder={placeholder}
                       onChange={(event) => changeWriting(event)}
                       defaultValue={value}
                       onKeyDown={(event) => onKeyDown(event)}
                       {...(!isAMP() && { autoCapitalize: "none" })}
                />
            </InputCheckboxComp>
        </StyledInputComp>
    );
};

const StyledInputComp = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 11px 17px;
  background-color: ${Colors.white};
  border-radius: 6px;
`;

const InputTitleComp = styled.div`
  font-family: 'Manrope', sans-serif;
  font-weight: 800;
  font-size: 18px;
  line-height: 30px;
  letter-spacing: 0;
  color: ${Colors.black};
  margin-bottom: 5px;

  @media ${deviceMax.tablet} {
    width: 100%;
    text-align: center;
    font-size: 12px;
  }
`;

const InputCheckboxComp = styled.div`
  position: relative;
  width: 100%;
  display: flex;

  > input {
    width: 100%;
    background: rgba(241, 241, 241, 0.5);
    border: 1px solid ${Colors.white};
    border-radius: 8px;
    padding: 14px 32px;
    font-family: 'Manrope', sans-serif;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0;
    color: ${Colors.black};
    outline: none;

    &::placeholder {
      color: ${Colors.black};
    }

    &:focus {
      &::placeholder {
        color: transparent;
      }
    }

    @media ${deviceMax.tablet} {
        background: ${Colors.white};
        border-radius: 10px;
        font-weight: 400;
        font-size: 16px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        padding: 11px 23px 11px 25px;

      &:focus {
        &::placeholder {
          color: transparent;
        }
      }
    }
  }
`;

export default InputComp;
